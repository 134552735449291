import React, { ReactElement } from 'react';
import Link from 'next/link';

import ArrowUp from '@engineering/icons/arrow-up';
import { LayoutLegacy } from '@silvertours/front-legacy-entities';
import { Runtime, BrandLogo } from '@silvertours/front-entities';

import { useLocale, useTranslations } from 'next-intl';
import { FooterBaseContent, FooterList, FooterContainer } from './Page.styles';

type LinkProps = {
  text: string;
  href: string;
  rel?: string;
  target?: '_blank';
  external?: boolean;
};

type LinkWrapperProps = {
  item: LinkProps;
  children: ReactElement;
};

const footerLinksDe = [
  'press',
  'company',
  'partners',
  'privacy',
  'compliance',
  'cookies',
  'faq',
  'gtc',
  'imprint',
  'newsletter',
  'job',
] as const;

const footerLinksFr = [
  'privacy',
  'gtc',
  'compliance',
  'cookies',
  'company',
  'info',
  'press',
  'partners',
  'faq',
  'support',
] as const;

const LinkWrapper = ({ item, children }: LinkWrapperProps) => {
  const external =
    Object.prototype.hasOwnProperty.call(item, 'external') && item.external;
  // links marked as 'external' use the standard anchor tag
  // instead of the Link component provided by next.js

  if (external) {
    return children;
  }

  return (
    <Link passHref href={item.href} legacyBehavior>
      {children}
    </Link>
  );
};

const PageFooter = () => {
  const { brandName } = Runtime.useBrand();
  const locale = useLocale();
  const footerLinksEntries = locale === 'de-DE' ? footerLinksDe : footerLinksFr;
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const t = useTranslations('features.navigationLegacy.footer');
  const links = footerLinksEntries.map(item => ({
    text: t.raw(`links.${item}.text`),
    href: t.raw(`links.${item}.href`),
    target: t.raw(`links.${item}.target`),
    rel: t.raw(`links.${item}.rel`),
    external: t.raw(`links.${item}.external`) === 'true',
  }));

  return (
    <LayoutLegacy.FooterSegment id="page-footer">
      <FooterContainer>
        <button type="button" onClick={scrollToTop} aria-label={t('backToTop')}>
          <ArrowUp />
        </button>

        <FooterBaseContent>
          <FooterList>
            {links.map(
              item =>
                !!item.text && (
                  <li key={`footer-${item.href}`}>
                    <LinkWrapper item={item}>
                      <a href={item.href} rel={item.rel} target={item.target}>
                        {item.text}
                      </a>
                    </LinkWrapper>
                  </li>
                ),
            )}
          </FooterList>
          <BrandLogo brand={brandName} height={25} plain />
        </FooterBaseContent>
      </FooterContainer>
    </LayoutLegacy.FooterSegment>
  );
};

export { PageFooter };
