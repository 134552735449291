import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  PlainPageContent,
} from '@silvertours/common-landingpages-view';
import { StageLegacy, ContentArticle } from '@silvertours/front-features';

import { Body } from '../body';

/**
 * This is a page template that only renders search form, introduction and content modules.
 * Create for black friday promo page, but can be used for rendering other pages where we don't
 * want to render legacy elements. To use the template, add necessary slug in i18n/json/app/pageContent/[LOCALE].ts
 */

const PlainPage = ({
  content: {
    article,
    introduction,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<PlainPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage content={stage.content} headline={primaryHeadline} />
      }
      content={({ container, containerModule, contentModule }) => [
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        container('content-modules', [
          contentModule(
            'article',
            <ContentArticle
              modules={article.segments}
              translations={translations}
            />,
          ),
        ]),
      ]}
      includeSearch={stage.content.includeSearch}
      {...commonProps}
    />
  );
};

export { PlainPage };
