import { Theme } from '@silvertours/front-shared';

import { Picture, SegmentImage, SegmentImageContainer } from './Segment.styles';
import { ImageSegmentProps, ConfigurableSegmentProps } from './props';

const isImageSegment = (
  props: ConfigurableSegmentProps,
): props is ImageSegmentProps =>
  Object.prototype.hasOwnProperty.call(props, 'image');

const SegmentImageBackground = (props: ConfigurableSegmentProps) => {
  if (!isImageSegment(props)) {
    return null;
  }

  const { id, image, isStage, includeSearch } = props;
  const showImageBreakpoint = 'lg';

  return (
    <SegmentImageContainer
      id={id}
      isStage={isStage}
      includeSearch={includeSearch}
    >
      {isStage && includeSearch ? (
        <Picture $breakpoint={showImageBreakpoint}>
          <source
            srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 1w"
            media={`(max-width: ${Theme.getBreakpoint(showImageBreakpoint)}px)`}
            sizes="1px"
          />
          <SegmentImage {...image} fill />
        </Picture>
      ) : (
        <SegmentImage {...image} fill priority />
      )}
    </SegmentImageContainer>
  );
};

export { SegmentImageBackground };
