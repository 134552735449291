import styled from '@emotion/styled';
import { Trustpilot } from '../TrustSeals';

const MobileSearchContainer = styled.div`
  position: relative;
  display: flex;
  height: 320px;
  padding: ${({ theme }) => theme.spacing['50']};
  z-index: 10;
  background: linear-gradient(
    103.44deg,
    ${({ theme }) => theme.color.primaryHighlight} 3.17%,
    ${({ theme }) => theme.color.brand} 100%
  );
  background-repeat: no-repeat;
`;

const TrustpilotWidget = styled(Trustpilot)`
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
`;

export { MobileSearchContainer, TrustpilotWidget };
