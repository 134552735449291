import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Heading1 } from '@silvertours/front-legacy-shared';

type AffiliateProps = {
  isAffiliate?: boolean;
};

const StageWrapper = styled.div`
  position: relative;
`;

const StageContentContainer = styled.div<AffiliateProps>`
  width: 100%;
  padding: 0;

  ${({ isAffiliate }) =>
    isAffiliate &&
    css`
      display: flex;
      height: 100vh;
      align-items: flex-start;
      justify-content: center;
    `}

  ${({ theme }) => theme.mq('sm')} {
    margin: 0 auto;
    background-color: transparent;
  }

  /* FIXME: This block causes layout issues with larger WidgetSearch, new type of StageContentContainer? */
  ${({ theme }) => theme.mq('lg')} {
    display: flex;
    height: 29.375rem;
    align-items: center;
  }
`;

const WizardSearchWrapper = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;

  ${({ theme }) => theme.mq('sm')} {
    display: none;
  }
`;

const StageCaption = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 1.875rem 0;

  ${({ theme }) => theme.mq('sm')} {
    width: auto;
  }

  ${({ theme }) => theme.mq('md')} {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    margin-right: 0.625rem;
    padding: 0.625rem 0.9375rem;
  }

  ${({ theme }) => theme.mq('lg')} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;

const StageLink = styled.a`
  display: none;

  ${({ theme }) => theme.mq('sm')} {
    display: block;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 29.375rem;
    width: 100vw;
    text-indent: -100em;
  }
`;

const BadgesContainer = styled.aside`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  place-items: flex-end;
  gap: 1rem;
  flex-direction: column;
`;

const TrustElement = styled.div`
  display: inline-flex;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledH1 = styled(Heading1)`
  margin: 0;
  padding: 1rem;
  font-size: 1.375rem;
  line-height: 1.2;
  text-align: center;
`;

export {
  BadgesContainer,
  StageContentContainer,
  StageCaption,
  StageLink,
  StageWrapper,
  TrustElement,
  StyledH1,
  WizardSearchWrapper,
};
